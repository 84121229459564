import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { StatementApi, PayXStatementResult, PayXStatement, DictionaryApi, PayXDictionaryValue, PayXStatementLight} from '../../api';
import ActionButton from '../../components/ActionButton';
import EditForm from '../../components/EditForm';
import AccXTextField from '../../components/fields/AccXTextField';
import { useApiContext } from '../../contexts/ApiContext';
import { useAppContext } from '../../contexts/AppContext';
import MasterLayout from '../../layouts/MasterLayout';
import SideElementLayout from '../../layouts/SideElementLayout';
import PredictStatementResultPanel from './panels/PredictStatementResultPanel';
import ExamplesPanel from './panels/ExamplesPanel';
import { useUserContext } from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DateTimeField from '../../components/fields/DateTimeField';
import NumericField from '../../components/fields/NumericField';
import ComboBoxField from '../../components/fields/ComboBoxField';
import { useNavigate } from 'react-router-dom';
import CheckboxField from '../../components/fields/CheckboxField';
import { MessageType } from '../../components/snackbar/MessageType';
import PredictStatementGeneralResultPanel from './panels/PredictStatementGeneralResultPanel';
import PredictStatementBankAccountResultPanel from './panels/PredictStatementBankAccountResultPanel';
import PredictStatementContractorResultPanel from './panels/PredictStatementContractorResultPanel';

export default function PredictStatementPage() {

    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const {user, isSigned} = useUserContext();
    const navigate = useNavigate();

    const statementApi = new StatementApi();

    const initStatementData : PayXStatement = {
        general: {
            isIncoming: true,
        },
    };

    const [statementData, setStatementData] = useState(initStatementData);
    const [result, setResult] = useState<PayXStatementResult | null>(null);
  
    function onStatementTitleChange(id: string, value: string) {
        setStatementData((prevState) => ({
            ...prevState, general: {
                ...prevState.general, statementTitle: value
            }}
        ));
    }

    const handleSubmit = (e: React.FormEvent | React.KeyboardEvent) => {
        e.preventDefault();
        predict();
    }

    function predict() {
        if (!!!statementData.general?.statementTitle) {
            showMessage(labels.enterStatementTitleToPredict, MessageType.Warning);
            return;
        }

        setResult(null);
        setIsLoading(true);

        if (isSigned) {
            request(statementApi.statementPredictPost(statementData)).then((response) => {
                if (response?.data) {
                    setResult(response.data);
                }
                setIsLoading(false);
            });
        }
        else {
            var light: PayXStatementLight = {
                statementTitle: statementData.general?.statementTitle,
            }

            request(statementApi.statementPredictUnauthorizedPost(light)).then((response) => {
                if (response?.data) {
                    setResult(response.data);
                }
                setIsLoading(false);
            });
        }
    }

    function reset() {
        setResult(null);
        setStatementData(initStatementData);
    }

    function setStatementExample(example : PayXStatement) {
        setResult(null);
        setStatementData(example);
    }

    const action = <ActionButton style={{marginLeft: '20px'}} className="actionButton" onClick={() => navigate('/userProfile')}>
        {labels.companyConfiguration}
    </ActionButton>

    return <MasterLayout navMenuAction={action}>
        <ExamplesPanel setStatementExample={setStatementExample}/>
        <SideElementLayout>
            <Stack className='centerPanel'>
                <EditForm>
                    <h4>{labels.enterStatementData}</h4>
                    <Divider style={{marginBottom: '20px'}}></Divider>
                    <form onSubmit={predict}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AccXTextField id='statementTitle'
                                    label={labels.statementDescription}
                                    value={statementData.general?.statementTitle}
                                    multiline
                                    rows={3}
                                    onChange={onStatementTitleChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleSubmit(event);
                                        }
                                    }}/>
                            </Grid>
                            {isSigned && <ExtendenedForm statementData={statementData} setStatementData={setStatementData}/>}
                            <Grid item xs={12}>
                                <ActionButton onClick={reset} className="resetButton">
                                    {labels.clean}
                                </ActionButton>
                                <ActionButton onClick={predict} 
                                    style={{float: 'right'}}>
                                    {labels.predict}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </form>
                </EditForm>
                {result && <>
                    <PredictStatementResultPanel resultData={result}/>
                    {user?.isAdmin && <>
                        {result.general && <PredictStatementGeneralResultPanel result={result.general}/>}
                        {result.bankAccount && <PredictStatementBankAccountResultPanel result={result.bankAccount}/>}
                        {result.contractor && <PredictStatementContractorResultPanel result={result.contractor}/>}
                    </>}
                </>}
            </Stack> 
        </SideElementLayout>
    </MasterLayout>
} 

function ExtendenedForm({statementData, setStatementData} : {statementData: PayXStatement, setStatementData: React.Dispatch<React.SetStateAction<PayXStatement>>}) {
    
    const dictionaryApi = new DictionaryApi();

    const [countries, setCountries] = useState<PayXDictionaryValue[]>([]);
    const [isLoadingCountries, setIsLoadingCountries] = useState(true);

    const [currencies, setCurrencies] = useState<PayXDictionaryValue[]>([]);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);

    const {request} = useApiContext();

    function fetchCountries() {
        request(dictionaryApi.dictionaryCountriesGet()).then((response) => {
            setIsLoadingCountries(false);
            if (response.ok && response.data) {
                setCountries(response.data);
            }
        });
    }

    function fetchCurrencies() {
        request(dictionaryApi.dictionaryCurrenciesGet()).then((response) => {
            setIsLoadingCurrencies(false);
            if (response.ok && response.data) {
                setCurrencies(response.data);
            }
        });
    }

    useEffect(() => {
        fetchCountries();
        fetchCurrencies();
    }, []);

    if (isLoadingCountries || isLoadingCurrencies) {
        return <></>
    }

    var comp = (statementData.general?.isIncoming ?? true) 
        ? <SenderAccordion statementData={statementData} setStatementData={setStatementData} countries={countries}/>
        : <RecipientAccordion statementData={statementData} setStatementData={setStatementData} countries={countries}/>;

    return <Grid item xs={12}>
        <GeneralInfoAccordion statementData={statementData} setStatementData={setStatementData} currencies={currencies}/>
        {comp}
        <TaxInfoAccordion statementData={statementData} setStatementData={setStatementData}/>
    </Grid>
}

function GeneralInfoAccordion({statementData, setStatementData, currencies} : {
    statementData: PayXStatement, 
    setStatementData: React.Dispatch<React.SetStateAction<PayXStatement>>,
    currencies: PayXDictionaryValue[]
}) {
    
    const {labels} = useAppContext();

    function onFieldValueChange(id: string, value: any) {
        setStatementData((prevState) => ({
            ...prevState, general: {
                ...prevState.general, [id]: value
            }
        }));
    }

    function isIncomingValueChange(event: any) {
        var value = event.target.value === 'true';

        setStatementData((prevState) => ({
            ...prevState, 
                general: {
                    ...prevState.general, isIncoming: value
                },
        }));
    }

    return <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            <Typography className='additionalDataPanelDecree' variant='subtitle1'>{labels.generalInformation}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ToggleButtonGroup exclusive
                        value={statementData.general?.isIncoming}
                        onChange={isIncomingValueChange}
                    >
                        <ToggleButton value={true}>{labels.incoming}</ToggleButton>
                        <ToggleButton value={false}>{labels.outgoing}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <div style={{height: '80px'}}/>
                <Grid item xs={12}>
                    <AccXTextField id='statementType' 
                        label={labels.transferType}
                        value={statementData.general?.statementType}
                        onChange={onFieldValueChange}/>
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField id='orderDate' 
                        label={labels.disposalDate}
                        value={statementData.general?.statementDate}
                        onChange={onFieldValueChange}/>
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField id='statementDate' 
                        label={labels.transferDate}
                        value={statementData.general?.statementDate}
                        onChange={onFieldValueChange}/>
                </Grid>
                <Grid item xs={6}>
                    <NumericField id='amount'
                        label={labels.amount} 
                        value={statementData.general?.amount}
                        decimals={2}
                        onChange={onFieldValueChange}/>
                </Grid>
                <Grid item xs={6}>
                    <ComboBoxField id='currency'
                        label={labels.currency} 
                        value={statementData.general?.currency}
                        items={currencies.map((x) => ({text: x.value, value: x.value}))}
                        onChange={onFieldValueChange}/>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}

function SenderAccordion({statementData, setStatementData, countries} : {
    statementData: PayXStatement, 
    setStatementData: React.Dispatch<React.SetStateAction<PayXStatement>>
    countries : PayXDictionaryValue[]
}) {
    
    const {labels} = useAppContext();

    function onSenderValueChange(id: string, value: any) {
        setStatementData((prevState) => ({
            ...prevState, sender: {
                ...prevState.sender, [id]: value
            }
        }));
    }

    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            <Typography className='additionalDataPanelDecree' variant='subtitle1'>{labels.issuerData}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccXTextField id='name'
                        label={labels.issuerName}
                        value={statementData.sender?.name}
                        onChange={onSenderValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='bankAccount' 
                        label={labels.issuerAccountNumber}
                        value={statementData.sender?.bankAccount}
                        onChange={onSenderValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='bankName'
                        label={labels.issuerBankName}
                        value={statementData.sender?.bankName}
                        onChange={onSenderValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='address'
                        label={labels.issuerAddress}
                        value={statementData.sender?.address}
                        onChange={onSenderValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ComboBoxField id='country'
                        label={labels.issuerCountry} 
                        value={statementData.sender?.country}
                        items={countries.map((x) => ({text: x.value, value: x.value}))}
                        onChange={onSenderValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='swift'
                        label={labels.issuerSwift}
                        value={statementData.sender?.swift}
                        onChange={onSenderValueChange}/>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}

function RecipientAccordion({statementData, setStatementData, countries} : {
    statementData: PayXStatement, 
    setStatementData: React.Dispatch<React.SetStateAction<PayXStatement>>
    countries : PayXDictionaryValue[]
}) {

    const {labels} = useAppContext();
   
    function onRecipientValueChange(id: string, value: any) {
        setStatementData((prevState) => ({
            ...prevState, recipient: {
                ...prevState.recipient, [id]: value
            }
        }));
    }

    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            <Typography className='additionalDataPanelDecree' variant='subtitle1'>{labels.recipientData}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccXTextField id='name'
                        label={labels.recipientName}
                        value={statementData.recipient?.name}
                        onChange={onRecipientValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='bankAccount' 
                        label={labels.recipientAccountNumber}
                        value={statementData.recipient?.bankAccount}
                        onChange={onRecipientValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='bankName'
                        label={labels.recipientBankName}
                        value={statementData.recipient?.bankName}
                        onChange={onRecipientValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='address'
                        label={labels.recipientAddress}
                        value={statementData.recipient?.address}
                        onChange={onRecipientValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ComboBoxField id='country'
                        label={labels.recipientCountry} 
                        value={statementData.recipient?.country}
                        items={countries.map((x) => ({text: x.value, value: x.value}))}
                        onChange={onRecipientValueChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='swift'
                        label={labels.recipientSwift}
                        value={statementData.recipient?.swift}
                        onChange={onRecipientValueChange}/>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}

function TaxInfoAccordion({statementData, setStatementData} : {statementData: PayXStatement, setStatementData: React.Dispatch<React.SetStateAction<PayXStatement>>}) {
    
    const {labels} = useAppContext();

    function onTextChange(id: string, value: string) {
        setStatementData((prevState) => ({
            ...prevState, taxDeclaration: {
                ...prevState.taxDeclaration, [id]: value
            }
        }));
    }

    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            <Typography className='additionalDataPanelDecree' variant='subtitle1'>{labels.taxData} </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <AccXTextField id='periodNumber'
                        label={labels.taxPeriodNumber}
                        value={statementData.taxDeclaration?.periodNumber}
                        onChange={onTextChange}/>
                </Grid>
                <Grid item xs={4}>
                    <AccXTextField id='periodType'
                        label={labels.taxPeriodType}
                        value={statementData.taxDeclaration?.periodType}
                        onChange={onTextChange}/>
                </Grid>
                <Grid item xs={4}>
                    <AccXTextField id='declarationType' 
                        label={labels.taxDeclarationNumber}
                        value={statementData.taxDeclaration?.declarationType}
                        onChange={onTextChange}/>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}