import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageType } from '../components/snackbar/MessageType';
import { handleResponse } from '../helpers/RequestHelper';
import { useAppContext } from './AppContext';

type ApiContextType = {
    request: <T>(promise:Promise<T>) => Promise<T>,
    // fetchBusinessClassifications: () => Promise<AccXBusinessClassificationResult[]>,
    // fetchTopics: () => Promise<AccXTopicResult[]>,
    // fetchVehicleUsagePurposes: () => Promise<AccXVehicleUsagePurposeResult[]>,
};

const ApiContext = createContext<ApiContextType | undefined>(undefined);

const useApiContext = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApiContext must be used within an ApiContextProvider');
    }
    return context;
};

function ApiContextProvider({children} : PropsWithChildren) {
    
    // var {isSigned, signOut} = useUserContext();
    var {labels, showMessage} = useAppContext();
    var navigate = useNavigate();
    const {setIsUpdating} = useAppContext();

    // const businessClassificationApi = new BusinessClassificationApi();
    // const topicApi = new TopicApi();
    // const vehicleUsagePurposeApi = new VehicleUsagePurposeApi();
    // const commonApi = new CommonApi();

    function handleError(error: any) {
        if (error.status === 401) {
            // if(isSigned) {
            //     signOut();
            //     showMessage(labels.sessionExpired, MessageType.Warning);
            // }
            // else {
            //     navigate('/');
            //     showMessage(labels.noAuthority, MessageType.Warning);
            // }
        } 
        else if (error.status === 429) {
            var totalSeconds = error.headers.get('retry-after');
            if (totalSeconds) {
                var min = Math.floor(totalSeconds / 60);
                var sec = totalSeconds % 60;
        
                showMessage(labels.limitExceededTryLater + ` ${min} min ${sec} s`, MessageType.Error);
            }
        }
        else {
            showMessage(labels.appErrorOccurred, MessageType.Error);
        }
    }

    
    
    function request<T>(promise: Promise<T>) : Promise<T> {
        return promise
            .then((response) => handleResponse(response, setIsUpdating))
            .catch(handleError);
    };

    // function fetchBusinessClassifications() : Promise<AccXBusinessClassificationResult[]> {
    //     return request(businessClassificationApi.businessClassificationAllGet()).then((response) => {
    //         if(response.ok && response.data) {
    //             return response.data;
    //         }
    //         else {
    //             return [];
    //         }
    //     });
    // }

    // function fetchTopics() : Promise<AccXTopicResult[]> {
    //     return request(topicApi.topicAllGet()).then((response) => {
    //         if(response.ok && response.data) {
    //             return response.data;
    //         }
    //         else {
    //             return [];
    //         }
    //     });
    // }

    // function fetchVehicleUsagePurposes() : Promise<AccXVehicleUsagePurposeResult[]> {
    //     return request(vehicleUsagePurposeApi.vehicleUsagePurposeAllGet()).then((response) => {
    //         if (response.ok && response.data) {
    //             return response.data;
    //         }
    //         else {
    //             return [];
    //         }
    //     })
    // }

    const apiContextValue: ApiContextType = {
        request,
        // fetchBusinessClassifications,
        // fetchTopics,
        // fetchVehicleUsagePurposes,
    };

    return <ApiContext.Provider value={apiContextValue}>
        { children }
    </ApiContext.Provider>
}

export { useApiContext, ApiContextProvider };