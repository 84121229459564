    
export const plTranslations : ITranslations = {
    aboutProjectButtonLabel: 'O projekcie',
    accountForCategoryLabel: 'Konto księgowe',
    accountingCategories: 'Kategorie księgowe',
    accountingPolicy: 'Polityka rachunkowości',
    accountingRules: 'Reguły księgowania',
    add: 'Dodaj',
    addAlternativeProductNameAction: 'Dodaj alternatywną nazwę',
    addAlternativeProductNameError: 'Wystąpił błąd podczas dodawania alternatywnej nazwy produktu',
    addAlternativeProductNamePopupTitle: 'Dodaj alternatywną nazwę dla produktu',
    addAlternativeProductNameSuccess: 'Alternatywna nazwa produktu została dodana',
    addCategoryLabel: 'Dodano nową kategorię',
    addCategoryRule: 'Dodaj regułę księgowania',
    addExampleErrorLabel: 'Wystąpił błąd podczas dodawania przykładu',
    addExampleLabel: 'Dodano nowy przykład',
    additionalData: 'Dane dodatkowe',
    additionalDataLabel: 'Dane dodatkowe',
    additionalDataPanel: 'Dane dodatkowe',
    additionalInfoContains: 'Dodatkowe informacje zawierają',
    additionalInfoNotContains: 'Dodatkowe informacje nie zawierają',
    additionalInformation: 'Dodatkowe informacje',
    addNewDecree: 'Nowy dekret',
    addPurchasePurposeLabel: 'Dodano nowy cel zakupu',
    addTag: 'Dodaj tag',
    addVatRegisterLabel: 'Dodano nowy rejestr VAT',
    admin: 'Admin',
    all: 'Wszystkie',
    alternativeNames: 'Alternatywne nazwy',
    alternativeProductName: 'Alternatywna nazwa',
    api: 'API',
    apiDocumentation: 'Dokumentacja API',
    apiDocumentationMarketing: 'Aby uzyskać dostęp do dokumentacji API skontaktuj się z nami:',
    appErrorOccurred: 'Wystąpił błąd aplikacji',
    appName: 'AccountantX',
    attachUserToExistingCustomerAction: 'Przypisz',
    attachUserToExistingCustomerPageName: 'Podłączanie użytkownika do firmy',
    attachUserToNewCustomerAction: 'Utwórz',
    attachUserToNewCustomerPageName: 'Aktywacja "sandbox" dla klienta',
    bcPrediction: 'Predykcja branży',
    BCPredictionLoading: 'Nie można aktualnie zapisać danych firmy - trwa predykcja branży',
    bestPrediction: 'Najlepiej dopasowany produkt',
    betterDecreeResultWithFullInfo: 'Aby zwiększyć poprawność predykcji wpisz treść pozycji oraz nazwę lub NIP sprzedawcy',
    bool: 'Tak / Nie',
    businessClassification: 'Branża',
    businessClassificationExplain: 'Jeżeli wybrany zostałaby następująca branża:',
    buyerRuleInfo: 'Reguła dotyczy nabywycy, którego',
    cantEditCategory: 'Nie można edytować predefiniowanej kategorii',
    category: 'Kategoria',
    categoryAccount: 'Konto księgowe',
    categoryAccountNonDeductible: 'Konto NKUP',
    categoryCostDeduction: 'Odliczenie kosztów',
    categoryDeletingSuccess: 'Usunięto kategorię',
    categoryName: 'Nazwa',
    categoryPanelTitle: 'Kategoria księgowa',
    categoryRuleAdded: 'Reguła została dodana',
    categoryRuleAddError: 'Wystąpił błąd podczas dodawania reguły księgowej',
    categoryRuleChanged: 'Zmiany reguły zostały zapisane',
    categoryRuleChangeError: 'Wystąpił błąd podczas zmian reguły księgowej',
    categoryRuleDeleted: 'Reguła została usunięta',
    categoryRuleDeletingError: 'Wystąpił błąd podczas usuwania reguły',
    categoryRuleHaveToSetSomething: 'Reguła musi coś ustawiać',
    categoryRuleNameRequired: 'Nazwa reguły jest wymagana',
    categoryRuleProcessingOrderInfo: 'Im niższa wartość tym reguła wykona się wcześniej',
    categoryVatDeduction: 'Odliczenia VAT',
    categoryVatRegister: 'Rejestr VAT',
    change: 'Zmień',
    clean: 'Wyczyść',
    closeActionConfirmationPopupTitle: 'Anuluj',
    cnCode: 'Kod CN',
    companyConfiguration: 'Konfiguracja firmy',
    companyConfigurationSettingButton: 'Konfiguracja firmy',
    companyData: 'Dane firmy',
    companyName: 'Nazwa firmy',
    companyName_optional: 'Nazwa firmy',
    companyTaxId: 'NIP',
    conditionsBuyer: 'Warunki - nabywca',
    conditionsMaterialsAndGoods: 'Warunki - towary/materiały',
    conditionsParameters: 'Warunki - parametry',
    conditionsPositions: 'Warunki - pozycje',
    conditionsPurposes: 'Warunki - cel zakupu/pojazdy/inne',
    conditionsSeller: 'Warunki - sprzedawca',
    conditionsTopics: 'Warunki - tematy',
    confirmActionConfirmationPopupTitle: 'Zapisz',
    confirmationPopupText: 'Zmiana branży może spowodować ustawienie innych wartości związanych z branżą w zakładce Polityka Rachunkowości',
    confirmationPopupTitle: 'Uwaga, branża została zmieniona',
    contactUsToDeleteAccount: 'W celu usunięcia konta skontaktuj się z nami pod adresem',
    contains: 'Zawiera',
    contractorPanelTitle: 'Dane klienta',
    costDeduction_100: '100% KUP',
    costDeduction_20: '20% KUP',
    costDeduction_75: '75% KUP',
    costDeduction_NKUP: 'NKUP',
    costDeduction_noInfo: 'Brak informacji',
    costDeduction_ToClarify: 'Do wyjaśnienia',
    costDeductionLabel: 'Odliczenie kosztów',
    customerManagement: 'Zarządzanie klientami',
    decree: 'Dekretuj',
    decreePageButtonLabel: 'Dekretacja',
    defaultValue: 'Wartość domyślna',
    delete: 'Usuń',
    deleteAlternativeProductNameAction: 'Usuń alternatywną nazwę',
    deleteAlternativeProductNamePopupTitle: 'Usuń alternatywną nazwę dla produktu',
    deleteExampleErrorLabel: 'Wystąpił błąd podczas usuwania przykładu',
    deleteExampleLabel: 'Usunięto przykład',
    deletingError: 'Wystąpił błąd podczas usuwania',
    edit: 'Edytuj',
    editCategory: 'Edytuj kategorię',
    editCategoryLabel: 'Zmieniono kategorię',
    editCategoryRule: 'Edycja reguły księgowania',
    editParameter: 'Edycja parametru',
    editProduct: 'Edytuj produkt',
    editPurchasePurposeLabel: 'Zmieniono cel zakupu',
    editPurchaseTarget: 'Edytuj cel zakupu',
    editTopicGroup: 'Edytuj grupę tematów',
    editVatRegister: 'Edytuj rejestr VAT',
    editVatRegisterLabel: 'Zmieniono rejestr VAT',
    email: 'Email',
    empty: 'Pusta',
    endConfigurationButtonLabel: 'Zakończ konfigurację',
    enterBCDetails: 'Wprowadź dane kontrahenta',
    enterCompanyDetails: 'Wprowadź dane firmy',
    enterInvoiceDataToDecree: 'Uzupełnij dane faktury, aby dekretować',
    enterInvoiceDetails: 'Wprowadź dane faktury',
    enterTestData: 'Testowanie towarów i materiałów ',
    enterUserDataToAttachExistingCustomer: 'Połącz konto użytkownika z firmą',
    enterUserDataToAttachNewCustomer: 'Przekształć konto użytkownika w firmie - aktywuj "sandbox"',
    equal: 'Równe',
    errorLabel: 'Wystąpił nieoczekiwany błąd',
    errorOccurred: 'Wystąpił błąd',
    errorSaveAttachUserToCustomerMessage: 'Wystąpił błąd przy zakładaniu struktur danych dla klienta',
    errorSaveAttachUserToExistingCustomerMessage: 'Wystąpił błąd przy podłączaniu użytkownika do klienta',
    exampleName: 'Nazwa przykładu',
    examples: 'Przykłady',
    exclamationElementPanelTitle: 'Możliwe inne kategorie księgowe',
    explainModeLabel: 'Pracuj w trybie prezentującym różne możliwości księgowania',
    externalId: 'Identyfikator produktu dostawcy',
    fieldRequired: 'Pole wymagane',
    filledIn: 'Uzupełnione',
    general: 'Ogólne',
    greaterOrQqual: 'Większe lub równe',
    greaterThan: 'Większe niż',
    gtinCode: 'Kod GTIN',
    gtu: 'Kod GTU',
    gtuCode: 'GTU',
    gtuCode_optional: 'Kod GTU',
    gusName: 'Nazwa firmy w GUS',
    hasRegNum: 'Numer rejestracyjny pojazdu',
    hasWarehouse: 'Moja firma posiada magazyny',
    history: 'Historia',
    historyIsEmpty: 'Brak wpisów',
    idxTagContains: 'Tag zawiera',
    idxTagNotContains: 'Tag nie zawiera',
    ignore: 'Ignoruj',
    indeksomat: 'Indeksomat',
    indexContains: 'Indeks zawiera',
    indexNotContains: 'Indeks nie zawiera',
    invoiceTopic: 'Temat',
    isActive: 'Czy aktywna',
    isCommodity: 'Czy towar?',
    isFixedAsset: 'Czy środek trwały?',
    isGtuEmpty: 'Czy GTU puste',
    isIntangibleAsset: 'Czy niematerialny?',
    isItemTextEmpty: 'Czy pozycja pusta',
    isLowValueEquipment: 'Czy niskocenny?',
    isPredefined: 'Czy predefiniowane',
    isPurchasePurposeEmpty: 'Czy cel zakupu pusty',
    isStoppingProcessing: 'Czy zatrzymuje procesowanie',
    isTaxIdEmpty: 'Czy NIP pusty',
    isVehicleUsagePurposeEmpty: ' Czy przeznaczenie pojazdu puste',
    itemTextContains: 'Pozycja zawiera',
    itemTextNotContains: 'Pozycja nie zawiera',
    key: 'Klucz',
    keywords: 'Keywords:',
    ksefFileLoadingError: 'Wystąpił błąd podczas wczytywania pliku. Faktura wymagana w formacie XML',
    ksefInvoicePosition: 'Pozycja faktury KSeF',
    ksefInvoicePositionNotFound: 'Faktura nie posiada pozycji o podanym numerze',
    lackOf: 'Brak',
    lessOrQqual: 'Mniejsze lub równe',
    lessThan: 'Mniejsze niż',
    likeIt: 'Podoba mi się',
    limitExceededTryLater: 'Przekreczono limit, zaloguj się lub dekretuj ponownie za:',
    loadedXMLFile: 'Wczytany plik XML',
    loadKSeFInvoice: 'Wczytaj fakturę',
    marketingIDXText: 'Nie masz aktywnego Indexomatu. Skontaktuj się z nami',
    materialsAndGoods: 'Towary i materiały',
    materialsAndGoodsMapping: 'Mapowanie towarów i materiałów',
    myCompany: 'Moja firma',
    name: 'Nazwa',
    nameContains: 'Nazwa zawiera',
    nameNotContains: 'Nazwa nie zawiera',
    negativeRate: 'Oceniłeś wynik negatywnie',
    newCategory: 'Nowa kategoria',
    newParameter: 'Dodaj parametr',
    newProduct: 'Nowy produkt',
    newPurchaseTarget: 'Nowy cel zakupu',
    newTopicGroup: 'Dodaj grupę tematów',
    newVatRegister: 'Nowy rejestr VAT',
    nip: 'NIP',
    no: 'Nie',
    noAuthority: 'Nie masz uprawnień',
    noData: 'Brak danych',
    noDataResultInfo: 'Brak wyników',
    noDataResultMessage: 'Wystąpił błąd',
    noResult: 'Brak wyników',
    notBusinessClassification: 'Nie branża',
    notContains: 'Nie zawiera',
    note: 'Notatka',
    notEqual: 'Nierówne',
    notFilledIn: 'Nieuzupełnione',
    notGtu: 'Nie kod GTU',
    notPurchasePurposeId: 'Nie cel zakupu',
    notTopicGroupKey: 'Temat faktury nie w grupie tematów',
    notTopicIds: 'Temat faktury nie jest',
    number: 'Liczba',
    ok: 'Ok',
    onBoardingExplain: 'Jeżeli wybrany zostałaby następujący parametr:',
    operatorType: 'Funkcja parametru',
    overwrite: 'Ustaw',
    parameter: 'Parametr',
    parameter1: 'Parametr 1',
    parameter2: 'Parametr 2',
    parameterAdded: 'Parametr został dodany',
    parameterAddingError: 'Parametr nie został dodany - wystąpił błąd',
    parameterAddingErrorNotUniqueKey: 'Klucz parametru musi być unikalny',
    parameterChanged: 'Parametr został zmieniony',
    parameterChangingError: 'Parametr nie został zmieniony - wystąpił błąd',
    parameterDeleted: 'Parametr został usunięty',
    parameterDeletingError: 'Wystąpił błąd podczas usuwania parametru',
    parameters: 'Parametry',
    phone: 'Telefon',
    pkd: 'PKD',
    pkdTitle: 'Nazwa PKD',
    pkwiu: 'PKWiU',
    pkwiuCode: 'Kod PKWiU',
    popupTitle: 'Dane firmy',
    position: 'Pozycja faktury',
    positionInfo: 'Dane pozycji',
    positionMeetsConditionsInfo: 'Pozycja spełnia warunki',
    positiveRate: 'Oceniłeś wynik pozytywnie',
    potentialCategoryNameExplainElementTitle: 'Potencjalna kategoria',
    predefinedIconTooltip: 'Predefiniowane',
    predictContractorButtonLabel: 'Rozpoznaj branżę',
    predictedTopic: 'Wykryty temat:',
    predictions: 'Predykcje',
    processingOrder: 'Kolejność wykonywania',
    product: 'Produkt',
    productAddError: 'Wystąpił błąd podczas dodawania produktu',
    productAddSuccess: 'Produkt został dodany',
    productAlternativeName: 'Alternatywna nazwa produktu',
    productAlternativeNameDeleted: 'Alternatywna nazwa produktu została usunięta',
    productAlternativeNameDeletingError: 'Wystąpił błąd podczas usuwania alternatywnej nazwy produktu',
    productDeleted: 'Produkt został usunięty',
    productDeletingError: 'Wystąpił błąd podczas usuwania produktu',
    productEditError: 'Wystąpił błąd podczas edycji produktu',
    productEditSuccess: 'Produkt został edytowany',
    productGroupContains: 'Grupa produktu zawiera',
    productGroupName: 'Grupa produktu',
    productGroupNameProductPanelLabel: 'Grupa produktu',
    productGroupNotContains: 'Grupa produktu nie zawiera',
    productHasNoAlternativeNames: 'Produkt nie posiada alternatywnych nazw',
    productId: 'Identyfikator produktu',
    productIndex: 'Indeks',
    productIndexNotUniqueError: 'Indeks nie jest unikalny',
    productIndexProductPanelLabel: 'Indeks produktu',
    productName: 'Nazwa produktu',
    productNameNotUniqueError: 'Nazwa produktu nie jest unikalna',
    productPanelTitle: 'Produkt',
    productsData: 'Produkty',
    productService: 'Typ produktu/uslugi',
    productTags: 'Tagi produktu',
    productType: 'Typ produktu',
    purchasePurpose: 'Cele zakupu',
    purchasePurposeDeletingSuccess: 'Usunięto cel zakupu',
    purchasePurposeId: 'Cel zakupu',
    purchasePurposeName: 'Nazwa',
    purchasePurposeNameNotUnique: 'Cel zakupu nie jest unikalny',
    purchasePurposes: 'Cel zakupu',
    purchaseTargetCode: 'Kod',
    purchaseTargetDefaultCategory: 'Kategoria domyślna',
    purchaseTargetExplain: 'Jeżeli wybrany zostałaby następujący cel zakupu:',
    purchaseTargetName: 'Nazwa',
    purchaseTargetNote: 'Notatka',
    purchaseTargets: 'Cele zakupu',
    rateUs: 'Oceń nasz wynik',
    report: 'Zgłoś uwagę',
    reset: 'Resetuj',
    resultNotSureLabel: 'Wynik wątpliwy',
    returnStopMessage: 'Zwróć komunikat zamiast wyniku',
    ruleName: 'Nazwa reguły',
    ruleNameExplain: 'Nazwa reguły: ',
    ruleNameExplainElementTitle: 'Dodatkowy warunek aby została wybrana ta kategoria',
    rules: 'Rules:',
    save: 'Zapisz',
    saveExample: 'Zapisz przykład',
    saveSuccessMessage: 'Dane firmy zostały zapisane',
    search: 'Szukaj',
    selectProduct: 'Wybierz poprawny produkt',
    selectTopic: 'Wybierz poprawny temat',
    sellerIndex: 'Indeks dostawcy',
    sellerRuleInfo: 'Reguła dotyczy sprzedawcy, którego',
    sendFeedback: 'Prześlij',
    service: 'Usługa',
    sessionExpired: 'Twoja sesja wygasła - zostałeś wylogowany',
    set: 'Ustaw',
    setDefault: 'Ustaw domyślne',
    signIn: 'Zaloguj',
    signInMarketing: 'Zaloguj się',
    signInMarketingDescription: 'Po zalogowaniu będziesz mieć dostęp do dodatkowych funkcji, a także wprowadzenia własnych ustawień',
    signInMarketingMoreOptions: ' i zyskaj więcej możliwości.',
    signInMarketingQuestion: 'Chcesz zobaczyć proponowany sposób księgowania?',
    signOut: 'Wyloguj',
    signOutSuccessfully: 'Zostałeś prawidłowo wylogowany',
    stopMessage: 'Komunikat zatrzymania',
    successSaveAttachUserToCustomerMessage: 'Założono struktury danych dla klienta',
    successSaveAttachUserToExistingCustomerMessage: 'Podłączono użytkownika do klienta',
    successSaveMessage: 'Dane firmy zostały zapisane',
    systemIsUpdating: 'Trwa aktualizacja systemu, proszę czekać',
    tag: 'Tag',
    tagRelated: 'Reguła dotyczy tagów na fakturze',
    targetValue: 'Wartość',
    taxId: 'NIP',
    taxIdToAttach: 'NIP klienta do przypisania użytkownika',
    test: 'Testuj',
    testing: 'Testowanie',
    text: 'Tekst',
    thanks: 'Dziękujemy',
    topicAndProductPrediction: 'Predykcja tematu i produktu',
    topicExplain: 'Jeżeli temat zakupu: ',
    topicGroupAdded: 'Grupa tematów została dodana',
    topicGroupAddingError: 'Wystąpił błąd podczas dodawania grupy tematów',
    topicGroupAddingErrorNotUniqueKey: 'Klucz grupy musi być unikalny',
    topicGroupChanged: 'Grupa tematów została zmieniona',
    topicGroupChangingError: 'Wystąpił błąd podczas zmieniania grupy tematów',
    topicGroupDeleted: 'Grupa tematów została usunięta',
    topicGroupDeletingError: 'Wystąpił błąd podczas usuwania grupy tematów',
    topicGroupExplain: ' zostałby przypisany do grupy:',
    topicGroupKey: 'Temat faktury w grupie tematów',
    topicGroups: 'Tematy związane z towarami i materiałami',
    topicIds: 'Temat faktury jest',
    topicPanelTitle: 'Temat faktury',
    topicPrediction: 'Predykcja tematu',
    topicRelated: 'Reguła dotyczy tematów',
    topics: 'Tematy',
    topicsRelatedToMaterialsAndGoods: 'Tematy powiązane z towarami i materiałami',
    topicTagContains: 'Tag faktury zawiera',
    topicTagNotContains: 'Tag faktury nie zawiera',
    trainProcuctPredictionError: 'Wystąpił błąd podczas treningu',
    trainProductPredictionSuccess: 'Dziękujemy! Mapowanie produktów będzie dzięki Tobie skuteczniejsze',
    tryAgainAdding: 'Spróbuj ponownie wprowadzając',
    type: 'Typ',
    unempty: 'Niepusta',
    unit: 'Jednostka',
    unitNetPrice: 'Cena jednostkowa netto – kwota',
    unitPriceGeValueLabel: 'Koszt jednostki >= [PLN]',
    unitPriceGTExplain: 'Jeżeli wprowadzono by cenę jednostkową większą lub równą:',
    unitPriceLTExplain: 'Jeżeli wprowadzono by cenę jednostkową mniejszą niż:',
    unitPriceLtValueLabel: 'Koszt jednostki < [PLN]',
    unitPriceParameter: 'Parametr',
    unitPriceParameterLabel: 'Parametr',
    unitPriceType: 'Typ',
    unitPriceValue: 'Wartość',
    userLoginAttachUserToCustomer: 'Login użytkownika',
    value: 'Wartość',
    vat: 'VAT',
    vatDeduction_0: 'Odliczenie 0% VAT',
    vatDeduction_100: 'Odliczenie 100% VAT',
    vatDeduction_50: 'Odliczenie 50% VAT',
    vatDeduction_noInfo: 'Brak informacji',
    vatDeduction_ToClarify: 'Do wyjaśnienia',
    vatDeductionLabel: 'Odliczenie VAT',
    vatId: 'NIP',
    vatRate: 'Stawka VAT',
    vatRegister_ComodityImport: 'Import towarów',
    vatRegister_ComodityInside: 'Wewn. nabycie towarów',
    vatRegister_CountryBuy: 'Zakup krajowy',
    vatRegister_CountryBuy_ST: 'Zakup krajowy - środki trwałe',
    vatRegister_ServiceImport: 'Import usług',
    vatRegister_ToClarify: 'Do wyjaśnienia',
    vatRegisterAccount: 'Konto',
    vatRegisterAccountLabel: 'Konto rejestr VAT',
    vatRegisterDeletingSuccess: 'Usunięto rejestr VAT',
    vatRegisterLabel: 'Rejestr VAT',
    vatRegisterName: 'Nazwa',
    vatRegisterNameNotUnique: 'Nazwa rejestru VAT nie jest unikalna',
    vatRegisters: 'Rejestry VAT',
    vehiclePurposeExplain: 'Jeżeli wybrany zostałaby następujący cel użytkowania pojazdu:',
    vehicleRealted: 'zakup dotyczący pojazdu',
    vehicles: 'Pojazdy',
    vehicleUsagePurpose: 'Pojazd użytkowany jako',
    vehicleUsagePurposeId: 'Przeznaczenie pojazdu',
    whatRuleSets: 'Co ustawia reguła',
    yes: 'Tak',
    predict: "Predykuj",
    statementDescription: "Opis przelewu",
    generalInformation: "Informacje ogólne",
    transferType: 'Typ przelewu',
    transferDate: "Data przelewu",
    disposalDate: "Data złożenia dyspozycji",
    amount: "Kwota",
    currency: "Waluta",
    issuerAndRecipientData: 'Dane wystawiającego i odbiorcy',
    issuerData: "Dane wystawiającego",
    issuerName: "Nazwa",
    issuerAccountNumber: "Numer konta",
    issuerBankName: "Nazwa banku",
    issuerAddress: "Adres",
    issuerCountry: "Kraj",
    issuerSwift: "BIC/SWIFT",
    recipientData: "Dane odbiorcy",
    recipientName: "Nazwa",
    recipientAccountNumber: "Numer konta",
    recipientBankName: "Nazwa banku",
    recipientAddress: "Adres",
    recipientCountry: "Kraj",
    recipientSwift: "BIC/SWIFT",
    taxData: "Dane podatkowe",
    taxPeriodNumber: "Numer okresu",
    taxPeriodType: "Typ okresu",
    taxDeclarationNumber: "Numer deklaracji",
    userData: "Dane użytkownika",
    userNIP: "NIP",
    accountNumber: "Numer konta",
    accountName: "Nazwa",
    bankAccounts: 'Konta bankowe',
    persons: "Osoby",
    addresses: "Adresy",
    dates: "Daty",
    numbers: "Numery",
    rawText: "RawText",
    log: "Log",
    swift: 'SWIFT',
    enterStatementData: 'Wprowadź dane przelewu',
    isIncoming: 'Przelew przychodzący',
    enterStatementTitleToPredict: 'Uzupełnij \'Opis przelewu\' lub skorzystaj z przykładów',
    companyConfigurationSaved: 'Konfiguracja Twojej firmy została zapisana',
    preparedText: 'PreparedText',
    incoming: "przychodzący",
    outgoing: "wychodzący"
}
