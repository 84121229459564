import { Divider, Grid, Stack, Typography } from "@mui/material";
import MasterLayout from "../../layouts/MasterLayout";
import SideElementLayout from "../../layouts/SideElementLayout";
import EditForm from "../../components/EditForm";
import AccXTextField from "../../components/fields/AccXTextField";
import { useAppContext } from "../../contexts/AppContext";
import { useUserContext } from "../../contexts/UserContext";
import ActionButton from "../../components/ActionButton";
import { PayXUserProfile, PayXUserBankAccount, UserApi } from "../../api";
import { useApiContext } from "../../contexts/ApiContext";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function UserProfilePage() {

    const initBankAccount : PayXUserBankAccount = {
        name: '',
        number: '',
        swift: '',
    };

    const {labels, showMessage} = useAppContext();
    const {userProfile, refreshUserProfile} = useUserContext();
    const navigate = useNavigate();
    const {request} = useApiContext();

    const userApi = new UserApi();

    const [userProfileData, setUserProfileData] = useState<PayXUserProfile>(); 

    useEffect(() => {
        setUserProfileData(userProfile ?? {});
    }, [userProfile]);

    function onUserProfileValueChange(id: string, value: string) {
        setUserProfileData({...userProfileData, [id]: value});
    }

    function onBankAccountValueChange(id: keyof PayXUserBankAccount, value: string, index: number) {
        var bankAccountsToSave = [...userProfileData?.bankAccounts ?? []]; 
        var accountToEdit = bankAccountsToSave[index];

        accountToEdit[id] = value;

        setUserProfileData({...userProfileData, bankAccounts: bankAccountsToSave});
    }

    function addBankAccount() {
        var bankAccountsToSave = [...userProfileData?.bankAccounts ?? []]; 
        bankAccountsToSave.push(initBankAccount);

        setUserProfileData({...userProfileData, bankAccounts: bankAccountsToSave});
    }

    function removeBankAccount(index : number) {
        var bankAccountsToSave = [...userProfileData?.bankAccounts ?? []]; 
        bankAccountsToSave.splice(index, 1);

        setUserProfileData({...userProfileData, bankAccounts: bankAccountsToSave});
    }

    function saveUserProfile() {
        if (userProfileData) {
            request(userApi.userUserProfilePost(userProfileData)).then((response) => {
                if (response.ok) {
                    showMessage(labels.companyConfigurationSaved);
                    refreshUserProfile();
                }
            });
        }
    }

    const action = <ActionButton style={{marginLeft: '20px'}} className="actionButton" onClick={() => navigate('/')}>
        {labels.endConfigurationButtonLabel}
    </ActionButton>

    return <MasterLayout navMenuAction={action}>
        <></>
        <SideElementLayout>
            <Stack className='centerPanel'>
                <EditForm>
                    <h4>{labels.enterCompanyDetails}</h4>
                    <Divider style={{marginBottom: '20px'}}></Divider>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <AccXTextField id='taxId'
                                    label={labels.nip}
                                    value={userProfileData?.taxId}
                                    onChange={onUserProfileValueChange}/>
                            </Grid>
                            <Grid item xs={9}>
                                <AccXTextField id='companyName'
                                    label={labels.companyName}
                                    value={userProfileData?.companyName}
                                    onChange={onUserProfileValueChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}><Typography>{labels.bankAccounts}</Typography></Grid>
                                    {userProfileData?.bankAccounts?.map((bankAccount, index) => {
                                        return <>
                                            <Grid item xs={3}>
                                                <AccXTextField id='name' 
                                                    label={labels.accountName} 
                                                    value={bankAccount.name} 
                                                    onChange={(id, value) => onBankAccountValueChange('name', value, index)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <AccXTextField id='number' 
                                                    label={labels.accountNumber} 
                                                    value={bankAccount.number} 
                                                    onChange={(id, value) => onBankAccountValueChange('number', value, index)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <AccXTextField id='swift' 
                                                    label={labels.swift} 
                                                    value={bankAccount.swift} 
                                                    onChange={(id, value) => onBankAccountValueChange('swift', value, index)}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <ActionButton onClick={() => removeBankAccount(index)}><FontAwesomeIcon icon={faTrash}/></ActionButton>
                                            </Grid>
                                        </>
                                    })}
                                    <Grid item xs={12}>
                                        <ActionButton onClick={addBankAccount}><FontAwesomeIcon icon={faPlus}/></ActionButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ActionButton onClick={saveUserProfile} style={{float: 'right'}}>
                                    {labels.save}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </form>
                </EditForm>
            </Stack>
        </SideElementLayout>
    </MasterLayout>
}