import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { PayXStatementBankAccountInfoResult } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";


export default function PredictStatementBankAccountResultPanel({result} : {result: PayXStatementBankAccountInfoResult}) {

    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            BankAccount
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={2}>bankName</Grid>
                <Grid item xs={10}>{result.bankName}</Grid>
            
                <Grid item xs={2}>bankCountry</Grid>
                <Grid item xs={10}>{result.bankCountry}</Grid>
                
                <Grid item xs={2}>number</Grid>
                <Grid item xs={10}>{result.number}</Grid>
                
                <Grid item xs={2}>swift</Grid>
                <Grid item xs={10}>{result.swift}</Grid>

                <Grid item xs={2}>isSubAccount</Grid>
                <Grid item xs={10}>{result.isSubAccount}</Grid>
                
                <Grid item xs={2}>subAccountName</Grid>
                <Grid item xs={10}>{result.subAccountName}</Grid>
                
                <Grid item xs={2}>isGovAccount</Grid>
                <Grid item xs={10}>{result.isGovAccount}</Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}