import './custom.css'
import '../node_modules/flag-icons/css/flag-icons.min.css';

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PredictStatementPage from './pages/predictStatement/PredictStatementPage';
import AboutProjectPage from './pages/about/AboutProjectPage';
import UserProfilePage from './pages/userProfile/UserProfilePage';
import { useUserContext } from './contexts/UserContext';

export default function App() {

  const {isSigned} = useUserContext();

  return <>
      <Routes>
        <Route path='*' element={<PredictStatementPage/>}/>
        <Route path='aboutProject' element={<AboutProjectPage/>}/>
        {isSigned && <Route path='userProfile' element={<UserProfilePage/>}/>}
      </Routes>
  </>
}
