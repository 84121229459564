
import { Chip, Grid, Paper } from '@mui/material';
import { PayXStatementDetailsResult, PayXStatementResult } from '../../../api';
import PanelInfo from '../../../components/PanelInfo';
import { useUserContext } from '../../../contexts/UserContext';
import { useAppContext } from '../../../contexts/AppContext';

function RandomColorChip({label, style} : {label: string, style?: React.CSSProperties}) {

    function getRandomBackgroundColor(label : string) {
        var hash = 0;
        for (var i = 0; i < label.length; i++) {
            hash = label.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        var color = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }

        return color;
    }

    function getLuminance(color: string) {

        var rgb = parseInt(color.slice(1), 16);

        var r = (rgb >> 16) & 0xff;
        var g = (rgb >>  8) & 0xff;
        var b = (rgb >>  0) & 0xff;
      
        var luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance / 255;
    };

    function getTextColor(bgColor: string) {
        var luminance = getLuminance(bgColor);
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    var innerStyle = {...style};

    var bgColor = getRandomBackgroundColor(label);

    innerStyle.backgroundColor = bgColor;
    innerStyle.color = getTextColor(bgColor);

    return <Chip size='small' label={label} style={innerStyle}/>
}

export default function PredictStatementResultPanel({resultData} : {resultData: PayXStatementResult}) {
    
    const {labels} = useAppContext();
    const {user} = useUserContext();

    function getDetailsInfo(details: PayXStatementDetailsResult) {


        function help(key: string, value: string) {
            if (value) {
                var keyValue = key;
                if (keyValue.toLowerCase().startsWith('is')) {
                    keyValue = keyValue.substring(2);
                }

                return <RandomColorChip label={keyValue}/> 
            }
            else {
                return <span>{key}: {value}</span>
            }
        }

        return <span style={{marginRight: '10px'}}>
            {details.additionalInfo && Object.entries(details.additionalInfo).map(([key, value]) => {

                return <span style={{verticalAlign: 'top', marginLeft: '-10px'}}>
                   {help(key, value)}
                </span>
            })}
        </span>
    }

    return <PanelInfo>
        <Grid container spacing={2}>
            <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.category}</span></Grid>
            <Grid item xs={10}>{resultData.entities?.category}</Grid>
            <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.persons}</span></Grid>
            <Grid item xs={10}>{resultData.entities?.persons?.map((person, index) => <span key={index}>
                    <Chip style={{marginRight: '5px'}} label={person.extractedText}/> 
                    {getDetailsInfo(person)}
                </span>)}
            </Grid>
            <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.addresses}</span></Grid>
            <Grid item xs={10}>{resultData.entities?.addresses?.map((address, index) => <span key={index}>
                    <Chip style={{marginRight: '5px'}} label={address.extractedText}/>
                    {getDetailsInfo(address)}
                </span>)}
            </Grid>
            <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.dates}</span></Grid>
            <Grid item xs={10}>{resultData.entities?.dates?.map((date, index) => <span key={index}>
                    <Chip style={{marginRight: '5px'}} label={date.extractedText}/>
                    {getDetailsInfo(date)}
                </span>)}
            </Grid>
            <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.numbers}</span></Grid>
            <Grid item xs={10}>{resultData.entities?.numbers?.map((number, index) => <div key={index} style={{display: 'inline-block'}}>
                    <Chip style={{marginRight: '10px'}} label={number.extractedText}/>
                    {getDetailsInfo(number)}
                </div>)}
            </Grid>
            {user?.isAdmin && <>
                {resultData.internal?.preparedText && <>
                    <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.preparedText}</span></Grid>
                    <Grid item xs={10}>{resultData.internal.preparedText}</Grid>
                </>}

                {resultData.internal?.rawText && <>
                    <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.rawText}</span></Grid>
                    <Grid item xs={10}>{resultData.internal.rawText}</Grid>
                </>}

                {resultData.internal?.log && <>
                    <Grid item xs={2}><span style={{fontWeight: 'bold'}}>{labels.log}</span></Grid>
                    <Grid item xs={10}><ul>{resultData.internal?.log?.map((log, index) => <li key={index}>{log}</li>)}</ul></Grid>
                </>}
            </>}
        </Grid>
    </PanelInfo>
}
