import { Grid, Paper } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../constants/config';
import { pagesNames } from '../../constants/pageNavigationConstans';
import { useAppContext } from '../../contexts/AppContext';
import LinkField from '../fields/LinkField';
import { languages } from '../../constants/languages';
// import { useUserContext } from '../../contexts/UserContext';

export function getLanguageButtons(language: languages, setLanguage: React.Dispatch<React.SetStateAction<languages>>) {

  const getFlag = (langauge: languages) => {
    const className = `fi fi-${languages[langauge]}`;
    return <span className={className} style={{cursor: 'pointer', marginRight: '5px'}} onClick={() => setLanguage(langauge)}/>
  }

  switch(language) {
    default:
    case languages.pl:
      return <>
        {getFlag(languages.gb)}
        {getFlag(languages.de)}
      </>
    case languages.gb:
      return <>
        {getFlag(languages.pl)}
        {getFlag(languages.de)}
      </>
    case languages.de:
      return <>
        {getFlag(languages.pl)}
        {getFlag(languages.gb)}
      </>
    }
}

export default function Footer() {

  const {engineVersion, labels, language, setLanguage} = useAppContext();
  // const {customerProfile} = useUserContext();

  const navigate = useNavigate();

  const footerHeight = 70;

  const footerRow : React.CSSProperties = {
    flex: 1, 
    textAlign: 'center',
    height: `${footerHeight/2}px`,
    lineHeight: `${footerHeight/2}px`,
  };

  const footerLink : React.CSSProperties = {
    padding: '0 10px'
  };

  return <Paper style={{height: `${footerHeight}px`, display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
    <div style={footerRow}>
      {getLanguageButtons(language, setLanguage)}
      {/* <span style={footerLink}>
        <LinkField onClick={() => navigate(`/${pagesNames.aboutProject}`)}>
          {labels.aboutProjectButtonLabel}
        </LinkField>
      </span> */}
      {/* <span style={footerLink}>
        <LinkField href={`/${pagesNames.swagger}`} target='_blank'>
          {labels.api}
        </LinkField>
      </span> */}
      <span style={footerLink}>
        <LinkField href={`mailto:${config.contactEmail}?Subject=${config.contactEmailSubject}`}>
          {config.contactEmail}
        </LinkField>
      </span>
      {/* {(customerProfile?.isAdmin ?? false) && 
        <span style={footerLink}>
        <LinkField onClick={() => navigate(`/${pagesNames.platformManagement}/${pagesNames.attachUserToNewCustomer}`)}>
          {labels.admin}
        </LinkField>
      </span>
      } */}
    </div>
    <div style={footerRow}>
      <Grid container>
        <Grid item xs={4} textAlign={'left'}>
          <span style={{fontSize: '13px', color: 'gray', marginLeft: '10px'}}>{engineVersion}</span>
        </Grid>
        <Grid item xs={4}>
          <span style={{fontSize: '13px'}}>{config.copyrightInfo}</span>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </div>
  </Paper>
}