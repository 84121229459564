export const config = {
    copyrightInfo: 'Copyright 2024 Columb Technologies. All rights reserved.',
    
    apiEmail: 'api@ai.taxxo.pl',
    apiEmailSubject:'Proszę o dostęp do dokumentacji PaymentX API',
    
    contactEmail: 'ai@ai.taxxo.pl',
    contactEmailSubject: 'PaymentX',

    deleteAccountEmail: 'ai@ai.taxxo.pl',
    deleteAccountEmailSubject: 'Prośba o usunięcie danych',
}