import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { PayXStatementContractorInfoResult } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";


export default function PredictStatementContractorResultPanel({result} : {result: PayXStatementContractorInfoResult}) {
    
    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            Contractor
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={2}>taxId</Grid>
                <Grid item xs={10}>{result.taxId}</Grid>
            
                <Grid item xs={2}>name</Grid>
                <Grid item xs={10}>{result.name}</Grid>
                
                <Grid item xs={2}>addressCountry</Grid>
                <Grid item xs={10}>{result.addressCountry}</Grid>
                
                <Grid item xs={2}>addressPostCode</Grid>
                <Grid item xs={10}>{result.addressPostCode}</Grid>

                <Grid item xs={2}>addressCity</Grid>
                <Grid item xs={10}>{result.addressCity}</Grid>

                <Grid item xs={2}>addressStreet</Grid>
                <Grid item xs={10}>{result.addressStreet}</Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}