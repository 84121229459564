import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayXUser, AuthApi, UserApi, PayXUserProfile } from '../api';
import { handleResponse } from '../helpers/RequestHelper';
import { useAppContext } from './AppContext';

type UserContextType = {
    isSigned: boolean,
    user?: PayXUser | null,
    userMode: UserMode,
    userProfile?: PayXUserProfile | null,
    refreshUserProfile: () => void,
    signOut: () => void,
    isLoadingData: boolean,
    resetAllData: boolean
};

enum UserMode {
    NotLoggedIn = 0,
    LoggedIn = 1,
    LoggedInWithBC = 2,
}

const UserContext = createContext<UserContextType | undefined>(undefined);
const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within an UserContextProvider');
    }
    return context;
};

function UserContextProvider({children} : PropsWithChildren) {

    const navigate = useNavigate();
    
    const [user, setUser] = useState<PayXUser | null>(null);
    const [userMode, setUserMode] = useState<UserMode>(UserMode.NotLoggedIn);
    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const [userProfile, setUserProfile] = useState<PayXUserProfile | null>(null);
    const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(true);

    const [resetAllData, setResetAllData] = useState(false);
    const isLoadingData = isLoadingUser || isLoadingUserProfile;

    const isSigned = userMode > UserMode.NotLoggedIn;

    const userApi = new UserApi();
    const authApi = new AuthApi();

    const {labels, showMessage, setIsUpdating} = useAppContext();

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (user) {
            setUserMode(UserMode.LoggedIn);
        } else {
            setUserMode(UserMode.NotLoggedIn);
        }
    }, [user])

    function fetchUser() {

        userApi.userGet().then((response) => {
            if (response.ok && response.data) {
                setUser(response.data);
                setIsLoadingUser(false);

                fetchUserProfile();
            } else {
                setUser(null);
                setIsLoadingUser(false);
                
                setUserProfile(null);
                setIsLoadingUserProfile(false);
            }
        }).catch((error) => {
            setUser(null);
            setIsLoadingUser(false);

            setUserProfile(null);
            setIsLoadingUserProfile(false);
        })
    }

    function fetchUserProfile() {
        userApi.userUserProfileGet().then((response) => { return handleResponse(response, setIsUpdating)}).then((response) => {
            setIsLoadingUserProfile(false);
            if (response.ok && response.data) {
                setUserProfile(response.data);
            } else {
                setUserProfile(null);
            }
        }).catch((error) => {
            setUserProfile(null);
            setIsLoadingUserProfile(false);
        })
    }

    function refreshUserProfile() {
        fetchUserProfile();
    }

    function signOut() {
        authApi.authSignOutPost().then((response) => {
            if (response.ok) {
                setUser(null);
                setUserProfile(null);
                setUserMode(UserMode.NotLoggedIn);
                setResetAllData(true);
                navigate('/');
                showMessage(labels.signOutSuccessfully);
            }
        })
    }

    const userContextValue : UserContextType = {
        isSigned,
        user,
        userMode,
        userProfile,
        refreshUserProfile,
        signOut,
        isLoadingData,
        resetAllData
    }

    return <UserContext.Provider value={userContextValue}>
        { children }
    </UserContext.Provider>
}

export { useUserContext, UserContextProvider, UserMode};