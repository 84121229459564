import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { PayXStatementGeneralResult } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";


export default function PredictStatementGeneralResultPanel({result} : {result: PayXStatementGeneralResult}) {

    return <Accordion>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            General
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={2}>isIncome</Grid>
                <Grid item xs={10}>{result.isIncome}</Grid>
            
                <Grid item xs={2}>isOutcome</Grid>
                <Grid item xs={10}>{result.isOutcome}</Grid>
                
                <Grid item xs={2}>isInternalBankTransaction</Grid>
                <Grid item xs={10}>{result.isInternalBankTransaction}</Grid>
                
                <Grid item xs={2}>transactionType</Grid>
                <Grid item xs={10}>{result.transactionType}</Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}