import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PanelInfo from "../../../components/PanelInfo";
import { ExampleApi, PayXStatement, StatementApi } from "../../../api";
import { useApiContext } from "../../../contexts/ApiContext";
import { Stack } from "@mui/material";
import ActionButton from "../../../components/ActionButton";
import { useAppContext } from "../../../contexts/AppContext";

export default function ExamplesPanel({setStatementExample} : {setStatementExample: (example: PayXStatement) => void}) {

    const {request} = useApiContext();
    const {labels} = useAppContext();
    const exampleApi = new ExampleApi();

    const [examples, setExamples] = useState<PayXStatement[]>([]);

    useEffect(() => {
        fetchExamples();
    }, [])

    function fetchExamples() {
        request(exampleApi.exampleAllGet()).then((response) => {
            if (response.data) {
                setExamples(response.data);
            }
        });
    }

    function onExampleClick(statement: PayXStatement) {
        if (statement.general && statement.recipient?.name) {
            statement.general.isIncoming = false;
        }

        setStatementExample(statement);
    }

    if (!examples || examples.length < 1) {
        return <span/>
    }

    return <PanelInfo>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <h4 style={{width: '80%'}}>{labels.examples}</h4>
        </div>
        <Stack>
            {examples && examples.map((exampleItem, exampleItemIndex) => {
                return <ActionButton key={exampleItemIndex} 
                    className='examplesItemsButton'
                    onClick={() => {
                        onExampleClick(exampleItem);
                    }}>
                    <div style={{width:'100%'}}><div style={{fontSize: '14px', textAlign:'left', display: 'inline-block'}}>{exampleItem.general?.statementTitle}</div></div>
                </ActionButton>
            })}
        </Stack>
    </PanelInfo>
}