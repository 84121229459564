import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMemo } from 'react';

type Props = {
    id: string,
    label?: string,
    value?: any,
    onChange?: (id: string, value: any) => void,
}

export default function DateTimeField({id, label, value, onChange} : Props) {

    var innerValue = useMemo(() => {

        if (value) {
            return dayjs(value); 
        }
        else {
            return null;
        }
    }, [value]);

    function innerOnChange(newValue: any) {
        if (onChange) onChange(id, newValue)
    }

    return <DatePicker
        value={innerValue} 
        label={label}
        onChange={(newValue) => innerOnChange(newValue)}
    />
} 