

import { useState } from 'react';
import './NavMenu.css';
import { AppBar, Typography, Container, Menu, Toolbar, Button, Box, Tooltip, Avatar, IconButton, MenuItem, Divider, ListItemIcon } from '@mui/material';
// import { useUserContext } from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faMicrosoft, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import LoginOptionMenu from '../login/LoginOptionMenu';
import { useAppContext } from '../../contexts/AppContext';
import { faCircleInfo, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../ActionButton';
import paymentsXLogo from '../../resources/payx_logo.png';
import { useUserContext } from '../../contexts/UserContext';

type Props = {
  contextAction?: JSX.Element,
}

export default function NavMenu({contextAction}: Props) {

  const { isSigned, user, userProfile, signOut } = useUserContext();

  const { labels, isMobile} = useAppContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSecondEl, setAnchorSecondEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToHomePage = () => navigate('/')

  
  return <AppBar position="sticky" style={{marginBottom:'10px', backgroundColor: 'var(--appBar)', zIndex: '10', height: '90px', justifyContent: 'center'}}>
  <Container maxWidth={false} style={{paddingRight: '36px', paddingLeft: '36px'}}
  //maxWidth="xl"
  >
    <Toolbar disableGutters >
      <Box>
        <Box sx={{textAlign: 'right', cursor: 'pointer', '&:hover': { textDecoration: 'none' }}}>
          <img height={50} src={paymentsXLogo} alt='Logo PaymentsX' onClick={goToHomePage} style={{ }}/>
        </Box>
      </Box>
      <Box sx={{ ml:'auto', float: 'right', textAlign: 'right', height: '100%'}}>
          {isSigned && <div style={{display: 'flex'}}>
            <div>
              <div style={{color: 'white'}}>{user?.userLogin}</div>
              <div style={{fontWeight: 'bold', textAlign: 'left', color: 'white'}}>{userProfile?.companyName}</div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {contextAction && contextAction}
              <ActionButton onClick={signOut} style={{marginLeft: '20px'}} className="actionButton">
                {labels.signOut}
              </ActionButton>
            </div>
          </div>}
          {!isSigned && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ActionButton onClick={handleClick} className="actionButton">
              {labels.signIn}
            </ActionButton>
            <LoginOptionMenu setAnchorEl={setAnchorEl} anchorElement={anchorEl} isOpen={open}></LoginOptionMenu>
          </div>}
      </Box>
    </Toolbar>
  </Container>
</AppBar>
}
