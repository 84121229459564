import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ApiContextProvider } from './contexts/ApiContext';
import { AppContextProvider } from './contexts/AppContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './contexts/UserContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as HTMLElement);
const baseUrl = document?.getElementsByTagName('base')[0]?.getAttribute('href') ?? '';

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BrowserRouter basename={baseUrl}>
      <AppContextProvider>
        <UserContextProvider>
          <ApiContextProvider>
            <App/>
          </ApiContextProvider>
        </UserContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </LocalizationProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
