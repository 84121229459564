/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * PayX-Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface APIResponse
 */
export interface APIResponse {
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof APIResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof APIResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum APIStatusResponse {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface BooleanAPIDataResponse
 */
export interface BooleanAPIDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAPIDataResponse
     */
    data?: boolean;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof BooleanAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PayXDictionaryValue
 */
export interface PayXDictionaryValue {
    /**
     * 
     * @type {number}
     * @memberof PayXDictionaryValue
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PayXDictionaryValue
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface PayXDictionaryValueIEnumerableAPIDataResponse
 */
export interface PayXDictionaryValueIEnumerableAPIDataResponse {
    /**
     * 
     * @type {Array<PayXDictionaryValue>}
     * @memberof PayXDictionaryValueIEnumerableAPIDataResponse
     */
    data?: Array<PayXDictionaryValue>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof PayXDictionaryValueIEnumerableAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PayXDictionaryValueIEnumerableAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXDictionaryValueIEnumerableAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PayXStatement
 */
export interface PayXStatement {
    /**
     * 
     * @type {PayXStatementGeneralInfo}
     * @memberof PayXStatement
     */
    general?: PayXStatementGeneralInfo;
    /**
     * 
     * @type {PayXStatementSender}
     * @memberof PayXStatement
     */
    sender?: PayXStatementSender;
    /**
     * 
     * @type {PayXStatementRecipient}
     * @memberof PayXStatement
     */
    recipient?: PayXStatementRecipient;
    /**
     * 
     * @type {PayXStatementTaxInfo}
     * @memberof PayXStatement
     */
    taxDeclaration?: PayXStatementTaxInfo;
}
/**
 * 
 * @export
 * @interface PayXStatementBankAccountInfoResult
 */
export interface PayXStatementBankAccountInfoResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementBankAccountInfoResult
     */
    number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementBankAccountInfoResult
     */
    isGovAccount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementBankAccountInfoResult
     */
    swift?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementBankAccountInfoResult
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementBankAccountInfoResult
     */
    bankCountry?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementBankAccountInfoResult
     */
    isSubAccount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementBankAccountInfoResult
     */
    subAccountName?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementContractorInfoResult
 */
export interface PayXStatementContractorInfoResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    addressStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    addressPostCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    addressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementContractorInfoResult
     */
    addressCountry?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementDetailsResult
 */
export interface PayXStatementDetailsResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementDetailsResult
     */
    extractedText?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PayXStatementDetailsResult
     */
    additionalInfo?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PayXStatementGeneralInfo
 */
export interface PayXStatementGeneralInfo {
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementGeneralInfo
     */
    isIncoming?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementGeneralInfo
     */
    statementTitle?: string;
    /**
     * 
     * @type {Date}
     * @memberof PayXStatementGeneralInfo
     */
    statementDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PayXStatementGeneralInfo
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PayXStatementGeneralInfo
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementGeneralInfo
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementGeneralInfo
     */
    statementType?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementGeneralResult
 */
export interface PayXStatementGeneralResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementGeneralResult
     */
    transactionType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementGeneralResult
     */
    isIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementGeneralResult
     */
    isOutcome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementGeneralResult
     */
    isInternalBankTransaction?: boolean;
}
/**
 * 
 * @export
 * @interface PayXStatementInternalResult
 */
export interface PayXStatementInternalResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementInternalResult
     */
    preparedText?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementInternalResult
     */
    rawText?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayXStatementInternalResult
     */
    log?: Array<string>;
}
/**
 * 
 * @export
 * @interface PayXStatementLight
 */
export interface PayXStatementLight {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementLight
     */
    statementTitle?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementListAPIDataResponse
 */
export interface PayXStatementListAPIDataResponse {
    /**
     * 
     * @type {Array<PayXStatement>}
     * @memberof PayXStatementListAPIDataResponse
     */
    data?: Array<PayXStatement>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof PayXStatementListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementRecipient
 */
export interface PayXStatementRecipient {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementRecipient
     */
    swift?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementResult
 */
export interface PayXStatementResult {
    /**
     * 
     * @type {PayXStatementTitleInfoResult}
     * @memberof PayXStatementResult
     */
    entities?: PayXStatementTitleInfoResult;
    /**
     * 
     * @type {PayXStatementGeneralResult}
     * @memberof PayXStatementResult
     */
    general?: PayXStatementGeneralResult;
    /**
     * 
     * @type {PayXStatementBankAccountInfoResult}
     * @memberof PayXStatementResult
     */
    bankAccount?: PayXStatementBankAccountInfoResult;
    /**
     * 
     * @type {PayXStatementContractorInfoResult}
     * @memberof PayXStatementResult
     */
    contractor?: PayXStatementContractorInfoResult;
    /**
     * 
     * @type {PayXStatementInternalResult}
     * @memberof PayXStatementResult
     */
    internal?: PayXStatementInternalResult;
}
/**
 * 
 * @export
 * @interface PayXStatementResultAPIDataResponse
 */
export interface PayXStatementResultAPIDataResponse {
    /**
     * 
     * @type {PayXStatementResult}
     * @memberof PayXStatementResultAPIDataResponse
     */
    data?: PayXStatementResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof PayXStatementResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PayXStatementResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementSender
 */
export interface PayXStatementSender {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementSender
     */
    swift?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementTaxInfo
 */
export interface PayXStatementTaxInfo {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementTaxInfo
     */
    periodNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementTaxInfo
     */
    periodType?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXStatementTaxInfo
     */
    declarationType?: string;
}
/**
 * 
 * @export
 * @interface PayXStatementTitleInfoResult
 */
export interface PayXStatementTitleInfoResult {
    /**
     * 
     * @type {string}
     * @memberof PayXStatementTitleInfoResult
     */
    category?: string;
    /**
     * 
     * @type {Array<PayXStatementDetailsResult>}
     * @memberof PayXStatementTitleInfoResult
     */
    persons?: Array<PayXStatementDetailsResult>;
    /**
     * 
     * @type {Array<PayXStatementDetailsResult>}
     * @memberof PayXStatementTitleInfoResult
     */
    addresses?: Array<PayXStatementDetailsResult>;
    /**
     * 
     * @type {Array<PayXStatementDetailsResult>}
     * @memberof PayXStatementTitleInfoResult
     */
    dates?: Array<PayXStatementDetailsResult>;
    /**
     * 
     * @type {Array<PayXStatementDetailsResult>}
     * @memberof PayXStatementTitleInfoResult
     */
    numbers?: Array<PayXStatementDetailsResult>;
}
/**
 * 
 * @export
 * @interface PayXUser
 */
export interface PayXUser {
    /**
     * 
     * @type {string}
     * @memberof PayXUser
     */
    userLogin?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayXUser
     */
    isAdmin?: boolean;
}
/**
 * 
 * @export
 * @interface PayXUserAPIDataResponse
 */
export interface PayXUserAPIDataResponse {
    /**
     * 
     * @type {PayXUser}
     * @memberof PayXUserAPIDataResponse
     */
    data?: PayXUser;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof PayXUserAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PayXUserAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXUserAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PayXUserBankAccount
 */
export interface PayXUserBankAccount {
    /**
     * 
     * @type {string}
     * @memberof PayXUserBankAccount
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXUserBankAccount
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXUserBankAccount
     */
    swift?: string;
}
/**
 * 
 * @export
 * @interface PayXUserProfile
 */
export interface PayXUserProfile {
    /**
     * 
     * @type {string}
     * @memberof PayXUserProfile
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof PayXUserProfile
     */
    companyName?: string;
    /**
     * 
     * @type {Array<PayXUserBankAccount>}
     * @memberof PayXUserProfile
     */
    bankAccounts?: Array<PayXUserBankAccount>;
}
/**
 * 
 * @export
 * @interface PayXUserProfileAPIDataResponse
 */
export interface PayXUserProfileAPIDataResponse {
    /**
     * 
     * @type {PayXUserProfile}
     * @memberof PayXUserProfileAPIDataResponse
     */
    data?: PayXUserProfile;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof PayXUserProfileAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PayXUserProfileAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayXUserProfileAPIDataResponse
     */
    message?: string;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Auth/signIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options: any = {}): FetchArgs {
            const localVarPath = `/Auth/signOut`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authSignInPost(provider, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authSignOutPost(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options?: any) {
            return AuthApiFp(configuration).authSignInPost(provider, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options?: any) {
            return AuthApiFp(configuration).authSignOutPost(options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignInPost(provider?: string, options?: any) {
        return AuthApiFp(this.configuration).authSignInPost(provider, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignOutPost(options?: any) {
        return AuthApiFp(this.configuration).authSignOutPost(options)(this.fetch, this.basePath);
    }

}
/**
 * CommonApi - fetch parameter creator
 * @export
 */
export const CommonApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options: any = {}): FetchArgs {
            const localVarPath = `/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanAPIDataResponse> {
            const localVarFetchArgs = CommonApiFetchParamCreator(configuration).statusGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options?: any) {
            return CommonApiFp(configuration).statusGet(options)(fetch, basePath);
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public statusGet(options?: any) {
        return CommonApiFp(this.configuration).statusGet(options)(this.fetch, this.basePath);
    }

}
/**
 * DictionaryApi - fetch parameter creator
 * @export
 */
export const DictionaryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCountriesGet(options: any = {}): FetchArgs {
            const localVarPath = `/Dictionary/countries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCurrenciesGet(options: any = {}): FetchArgs {
            const localVarPath = `/Dictionary/currencies`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryApi - functional programming interface
 * @export
 */
export const DictionaryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCountriesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXDictionaryValueIEnumerableAPIDataResponse> {
            const localVarFetchArgs = DictionaryApiFetchParamCreator(configuration).dictionaryCountriesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCurrenciesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXDictionaryValueIEnumerableAPIDataResponse> {
            const localVarFetchArgs = DictionaryApiFetchParamCreator(configuration).dictionaryCurrenciesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DictionaryApi - factory interface
 * @export
 */
export const DictionaryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCountriesGet(options?: any) {
            return DictionaryApiFp(configuration).dictionaryCountriesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryCurrenciesGet(options?: any) {
            return DictionaryApiFp(configuration).dictionaryCurrenciesGet(options)(fetch, basePath);
        },
    };
};

/**
 * DictionaryApi - object-oriented interface
 * @export
 * @class DictionaryApi
 * @extends {BaseAPI}
 */
export class DictionaryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryCountriesGet(options?: any) {
        return DictionaryApiFp(this.configuration).dictionaryCountriesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryCurrenciesGet(options?: any) {
        return DictionaryApiFp(this.configuration).dictionaryCurrenciesGet(options)(this.fetch, this.basePath);
    }

}
/**
 * ExampleApi - fetch parameter creator
 * @export
 */
export const ExampleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/Example/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExampleApi - functional programming interface
 * @export
 */
export const ExampleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXStatementListAPIDataResponse> {
            const localVarFetchArgs = ExampleApiFetchParamCreator(configuration).exampleAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExampleApi - factory interface
 * @export
 */
export const ExampleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options?: any) {
            return ExampleApiFp(configuration).exampleAllGet(options)(fetch, basePath);
        },
    };
};

/**
 * ExampleApi - object-oriented interface
 * @export
 * @class ExampleApi
 * @extends {BaseAPI}
 */
export class ExampleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExampleApi
     */
    public exampleAllGet(options?: any) {
        return ExampleApiFp(this.configuration).exampleAllGet(options)(this.fetch, this.basePath);
    }

}
/**
 * StatementApi - fetch parameter creator
 * @export
 */
export const StatementApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PayXStatement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictPost(body?: PayXStatement, options: any = {}): FetchArgs {
            const localVarPath = `/Statement/predict`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PayXStatement" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayXStatementLight} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictUnauthorizedPost(body?: PayXStatementLight, options: any = {}): FetchArgs {
            const localVarPath = `/Statement/predict/unauthorized`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PayXStatementLight" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatementApi - functional programming interface
 * @export
 */
export const StatementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PayXStatement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictPost(body?: PayXStatement, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXStatementResultAPIDataResponse> {
            const localVarFetchArgs = StatementApiFetchParamCreator(configuration).statementPredictPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PayXStatementLight} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictUnauthorizedPost(body?: PayXStatementLight, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXStatementResultAPIDataResponse> {
            const localVarFetchArgs = StatementApiFetchParamCreator(configuration).statementPredictUnauthorizedPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatementApi - factory interface
 * @export
 */
export const StatementApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PayXStatement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictPost(body?: PayXStatement, options?: any) {
            return StatementApiFp(configuration).statementPredictPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PayXStatementLight} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementPredictUnauthorizedPost(body?: PayXStatementLight, options?: any) {
            return StatementApiFp(configuration).statementPredictUnauthorizedPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * StatementApi - object-oriented interface
 * @export
 * @class StatementApi
 * @extends {BaseAPI}
 */
export class StatementApi extends BaseAPI {
    /**
     * 
     * @param {PayXStatement} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementPredictPost(body?: PayXStatement, options?: any) {
        return StatementApiFp(this.configuration).statementPredictPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PayXStatementLight} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementPredictUnauthorizedPost(body?: PayXStatementLight, options?: any) {
        return StatementApiFp(this.configuration).statementPredictUnauthorizedPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options: any = {}): FetchArgs {
            const localVarPath = `/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfileGet(options: any = {}): FetchArgs {
            const localVarPath = `/User/userProfile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayXUserProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfilePost(body?: PayXUserProfile, options: any = {}): FetchArgs {
            const localVarPath = `/User/userProfile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PayXUserProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXUserAPIDataResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfileGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PayXUserProfileAPIDataResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userUserProfileGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PayXUserProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfilePost(body?: PayXUserProfile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userUserProfilePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any) {
            return UserApiFp(configuration).userGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfileGet(options?: any) {
            return UserApiFp(configuration).userUserProfileGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {PayXUserProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserProfilePost(body?: PayXUserProfile, options?: any) {
            return UserApiFp(configuration).userUserProfilePost(body, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: any) {
        return UserApiFp(this.configuration).userGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUserProfileGet(options?: any) {
        return UserApiFp(this.configuration).userUserProfileGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PayXUserProfile} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUserProfilePost(body?: PayXUserProfile, options?: any) {
        return UserApiFp(this.configuration).userUserProfilePost(body, options)(this.fetch, this.basePath);
    }

}
